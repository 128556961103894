import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.js";

import { readState } from "@/__main__/app-state.mjs";
import {
  ItemTarget,
  mapSockets,
  ModList,
  Placeholder,
  SharedStyled,
} from "@/game-destiny2/components/LoadoutBuilderComponents.jsx";
import type { PickerTarget } from "@/game-destiny2/components/LoadoutBuilderPickers.jsx";
import {
  ItemPicker,
  usePickerState,
} from "@/game-destiny2/components/LoadoutBuilderPickers.jsx";
import { SUB_CATEGORIES } from "@/game-destiny2/constants/category.mjs";
import { BUCKETS } from "@/game-destiny2/constants/inventory-buckets.mjs";
import { RARITY } from "@/game-destiny2/constants/rarity.mjs";
import { SEASON } from "@/game-destiny2/constants/season.mjs";
import {
  ARMOR_STAT_MAP,
  ARMOR_STAT_ORDER,
} from "@/game-destiny2/constants/stats.mjs";
import type { InventoryItem } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import type { Armor, Mod, Stat } from "@/game-destiny2/models/model-wiki.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  gridCss: () => css`
    --card-w: 255px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--card-w), 1fr));
    justify-content: center;
    gap: 1px;

    overflow: hidden;

    .armor-card {
      position: relative;
      width: 100%;

      padding: var(--sp-8) var(--sp-6) var(--sp-6);
      display: flex;
      flex-direction: column;
      gap: var(--sp-6);

      background-color: var(--card-surface);

      .label {
        max-width: 15ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .stats {
        display: grid;
        grid-template-columns: min-content minmax(0, 1fr) 3ch;
        align-items: center;
        column-gap: var(--sp-5);
        row-gap: var(--sp-2);

        color: var(--shade0-75);
      }

      .mod-placeholder {
        height: var(--sp-6);
      }

      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        inset: -1px;

        background-color: var(--card-inset-border);
      }
    }
  `,
};

export const LoadoutBuilderArmor = ({
  selectedCharId,
}: {
  selectedCharId?: string;
}) => {
  const { t } = useTranslation();
  const {
    settings: {
      lastLoggedInIdByGame: { destiny2: bungieId },
    },
    destiny2: {
      characterLoadout,
      wiki: { armor, mods, stats },
    },
  } = useSnapshot(readState);

  const [_updateInProgress, pickerProps, openPicker] = usePickerState(
    bungieId,
    selectedCharId,
  );

  const handleOpenPicker = (target: PickerTarget) =>
    openPicker
      ? (socket: number = null) => openPicker(target, socket)
      : undefined;

  const character = characterLoadout.characters?.[selectedCharId];
  const equipment = character?.equipment ?? {};

  const helmetInstance = equipment[BUCKETS.helmet];
  const gauntletsInstance = equipment[BUCKETS.gauntlets];
  const chestInstance = equipment[BUCKETS.chest];
  const legsInstance = equipment[BUCKETS.leg];
  const classItemInstance = equipment[BUCKETS.classItem];
  return (
    <Card padding="0" className={Styled.gridCss()}>
      <ArmorCard
        placeholder={Placeholder.Helmet}
        placeholderText={t("destiny2:helmetArmor", "Helmet")}
        armor={armor?.[helmetInstance?.hash]}
        transmog={armor?.[helmetInstance?.overrideStyleItemHash]}
        instance={helmetInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("helmet")}
      />
      <ArmorCard
        placeholder={Placeholder.Gauntlets}
        placeholderText={t("destiny2:gauntletsArmor", "Gauntlets")}
        armor={armor?.[gauntletsInstance?.hash]}
        transmog={armor?.[gauntletsInstance?.overrideStyleItemHash]}
        instance={gauntletsInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("gauntlets")}
      />
      <ArmorCard
        placeholder={Placeholder.Chest}
        placeholderText={t("destiny2:chestArmor", "Chest")}
        armor={armor?.[chestInstance?.hash]}
        transmog={armor?.[chestInstance?.overrideStyleItemHash]}
        instance={chestInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("chest")}
      />
      <ArmorCard
        placeholder={Placeholder.Legs}
        placeholderText={t("destiny2:legsArmor", "Legs")}
        armor={armor?.[legsInstance?.hash]}
        transmog={armor?.[legsInstance?.overrideStyleItemHash]}
        instance={legsInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("leg")}
      />
      <ArmorCard
        placeholder={Placeholder.Class}
        placeholderText={t("destiny2:classArmor", "Class")}
        armor={armor?.[classItemInstance?.hash]}
        transmog={armor?.[classItemInstance?.overrideStyleItemHash]}
        instance={classItemInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("classItem")}
      />
      {armor && stats && <ItemPicker {...pickerProps} />}
    </Card>
  );
};

const ArmorCard = ({
  placeholder: Placeholder,
  placeholderText,
  armor,
  transmog,
  instance,
  mods,
  statMap,
  onOpenPicker,
}: {
  placeholder: SVGComponent;
  placeholderText: string;
  armor: Armor;
  transmog: Armor;
  instance: InventoryItem;
  mods: Record<number, Mod>;
  statMap: Record<number, Stat>;
  onOpenPicker?(socket?: number): void;
}) => {
  const { t } = useTranslation();

  const instanceMods = armor?.sockets.map(mapSockets(mods, instance?.sockets));
  const stats = instance?.stats ?? {};
  return (
    <div className="armor-card">
      <div className="flex column gap-2 align-center">
        <ItemTarget
          iconOverlay={
            !!armor && (armor.watermarkUri ?? SEASON[armor.seasonId]?.icon)
          }
          lightLevel={instance?.primaryStatValue}
          icon={transmog?.iconUri || armor?.iconUri || <Placeholder />}
          onClick={armor ? () => onOpenPicker() : undefined}
        />
        <div className="flex column align-center">
          <p className="type-title shade1 label">
            {armor?.label ?? placeholderText}
          </p>
          <p className="type-caption shade2">
            {armor
              ? t("destiny2:rarityItem", "{{rarity}} {{item}}", {
                  rarity: t(...RARITY[armor.rarity].label),
                  item: t(...SUB_CATEGORIES[armor.subCategory].label),
                })
              : placeholderText}
          </p>
        </div>
      </div>
      <div className="flex column gap-2">
        <p className="type-overline shade3">{t("destiny2:mods", "Mods")}</p>
        {instanceMods ? (
          <ModList
            mods={instanceMods}
            statMap={statMap}
            onOpenPicker={armor ? onOpenPicker : undefined}
          />
        ) : (
          <div className="mod-placeholder" />
        )}
      </div>
      <div className="flex column gap-2">
        <p className="type-overline shade3">
          {t("common:statistics", "Statistics")}
        </p>
        <div className="stats">
          {ARMOR_STAT_ORDER.map((hash) => {
            const { icon: Icon, label } = ARMOR_STAT_MAP[hash];
            return (
              <React.Fragment key={hash}>
                <div className="flex align-center gap-2">
                  <Icon />
                  <span>{t(...label)}</span>
                </div>
                <div {...classNames(SharedStyled.meterCss(), "meter")}>
                  <div
                    className="bar"
                    style={{ width: `${instance?.stats[hash] ?? 0}%` }}
                  />
                </div>
                <span className="text-end">{stats[hash] ?? "--"}</span>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
