import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.js";

import { readState } from "@/__main__/app-state.mjs";
import {
  ItemTarget,
  mapSockets,
  ModList,
  Placeholder,
  SharedStyled,
} from "@/game-destiny2/components/LoadoutBuilderComponents.jsx";
import type { PickerTarget } from "@/game-destiny2/components/LoadoutBuilderPickers.jsx";
import {
  ItemPicker,
  usePickerState,
} from "@/game-destiny2/components/LoadoutBuilderPickers.jsx";
import { Recoil } from "@/game-destiny2/components/Recoil.jsx";
import { SUB_CATEGORIES } from "@/game-destiny2/constants/category.mjs";
import { BUCKETS } from "@/game-destiny2/constants/inventory-buckets.mjs";
import { RARITY } from "@/game-destiny2/constants/rarity.mjs";
import { SEASON } from "@/game-destiny2/constants/season.mjs";
import { STATS, WEAPON_STAT_ORDER } from "@/game-destiny2/constants/stats.mjs";
import type { InventoryItem } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import type { Mod, Stat, Weapon } from "@/game-destiny2/models/model-wiki.mjs";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  gridCss: () => css`
    --card-w: 255px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--card-w), 1fr));
    justify-content: center;
    gap: 1px;

    overflow: hidden;

    .weapon-card {
      position: relative;
      width: 100%;

      padding: var(--sp-8) var(--sp-6) var(--sp-6);
      display: flex;
      flex-direction: column;
      gap: var(--sp-6);

      background-color: var(--card-surface);

      .label {
        max-width: 15ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .stats {
        --recoil-bg: var(--shade6-50);
        --recoil-color: var(--blue);

        display: grid;
        grid-template-columns: min-content minmax(0, 1fr) 3ch;
        align-items: center;
        column-gap: var(--sp-5);
        row-gap: var(--sp-2);

        color: var(--shade0-75);

        .name {
          text-wrap: nowrap;
        }
      }

      .mod-placeholder {
        height: var(--sp-6);
      }

      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        inset: -1px;

        background-color: var(--card-inset-border);
      }
    }
  `,
};

export const LoadoutBuilderWeapon = ({
  selectedCharId,
}: {
  selectedCharId?: string;
}) => {
  const { t } = useTranslation();
  const {
    settings: {
      lastLoggedInIdByGame: { destiny2: bungieId },
    },
    destiny2: {
      characterLoadout,
      wiki: { weapons, mods, stats },
    },
  } = useSnapshot(readState);

  const [_updateInProgress, pickerProps, openPicker] = usePickerState(
    bungieId,
    selectedCharId,
  );

  const handleOpenPicker = (target: PickerTarget) =>
    openPicker
      ? (socket: number = null) => openPicker(target, socket)
      : undefined;

  const character = characterLoadout.characters?.[selectedCharId];
  const equipment = character?.equipment ?? {};
  const kineticInstance = equipment[BUCKETS.kinetic];
  const energyInstance = equipment[BUCKETS.energy];
  const powerInstance = equipment[BUCKETS.power];
  return (
    <Card padding="0" className={Styled.gridCss()}>
      <WeaponCard
        placeholder={Placeholder.Weapon}
        placeholderText={t("destiny2:kineticWeapon", "Kinetic Weapon")}
        weapon={weapons?.[kineticInstance?.hash]}
        instance={kineticInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("kinetic")}
      />
      <WeaponCard
        placeholder={Placeholder.Weapon}
        placeholderText={t("destiny2:energyWeapon", "Energy Weapon")}
        weapon={weapons?.[energyInstance?.hash]}
        instance={energyInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("energy")}
      />
      <WeaponCard
        placeholder={Placeholder.Weapon}
        placeholderText={t("destiny2:powerWeapon", "Power Weapon")}
        weapon={weapons?.[powerInstance?.hash]}
        instance={powerInstance}
        mods={mods}
        statMap={stats}
        onOpenPicker={handleOpenPicker("power")}
      />
      {weapons && stats && <ItemPicker {...pickerProps} />}
    </Card>
  );
};

const WeaponCard = ({
  placeholder: Placeholder,
  placeholderText,
  weapon,
  instance,
  mods,
  statMap,
  onOpenPicker,
}: {
  placeholder: SVGComponent;
  placeholderText: string;
  weapon: Weapon;
  instance: InventoryItem;
  mods: Record<number, Mod>;
  statMap: Record<number, Stat>;
  onOpenPicker?(socket?: number): void;
}) => {
  const { t } = useTranslation();

  const instanceMods = weapon?.sockets.map(mapSockets(mods, instance?.sockets));
  const stats = instance?.stats ?? {};
  return (
    <div className="weapon-card">
      <div className="flex column gap-2 align-center">
        <ItemTarget
          icon={weapon?.iconUri ?? <Placeholder />}
          iconOverlay={
            !!weapon && (weapon.watermarkUri ?? SEASON[weapon.seasonId]?.icon)
          }
          lightLevel={instance?.primaryStatValue}
          onClick={weapon ? () => onOpenPicker() : undefined}
        />
        <div className="flex column align-center">
          <p className="type-title shade1 label">
            {weapon?.label ?? placeholderText}
          </p>
          <p className="type-caption shade2">
            {weapon
              ? t("destiny2:rarityItem", "{{rarity}} {{item}}", {
                  rarity: t(...RARITY[weapon.rarity].label),
                  item: t(...SUB_CATEGORIES[weapon.subCategory].label),
                })
              : placeholderText}
          </p>
        </div>
      </div>
      <div className="flex column gap-2">
        <p className="type-overline shade3">{t("destiny2:mods", "Mods")}</p>
        {instanceMods ? (
          <ModList
            mods={instanceMods}
            statMap={statMap}
            onOpenPicker={weapon ? onOpenPicker : undefined}
          />
        ) : (
          <div className="mod-placeholder" />
        )}
      </div>
      <div className="flex column gap-2">
        <p className="type-overline shade3">
          {t("common:statistics", "Statistics")}
        </p>
        <div className="type-caption--semi stats">
          {!!stats[STATS.rpm] && (
            <>
              <span className="name">{t("destiny2:rpm", "RPM")}</span>
              <div className="filler" />
              <span className="amt">{stats[STATS.rpm]}</span>
            </>
          )}
          {WEAPON_STAT_ORDER.map(
            (hash) =>
              stats[hash] !== undefined && (
                <React.Fragment key={hash}>
                  <span className="name">{statMap[hash].label}</span>
                  <div {...classNames(SharedStyled.meterCss(), "meter")}>
                    <div
                      className="bar"
                      style={{ width: `${stats[hash] ?? 0}%` }}
                    />
                  </div>
                  <span className="amt">{stats[hash]}</span>
                </React.Fragment>
              ),
          )}
          {stats[STATS.recoil] !== undefined && (
            <>
              <span className="name">{statMap[STATS.recoil].label}</span>
              <Recoil hideVal value={stats[STATS.recoil]} />
              <span className="amt">{stats[STATS.recoil]}</span>
            </>
          )}
          {!!stats[STATS.magSize] && (
            <>
              <span className="name">{statMap[STATS.magSize].label}</span>
              <div className="filler" />
              <span className="amt">{stats[STATS.magSize]}</span>
            </>
          )}
          {!!stats[STATS.ammoCap] && (
            <>
              <span className="name">{statMap[STATS.ammoCap].label}</span>
              <div className="filler" />
              <span className="amt">{stats[STATS.ammoCap]}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
