import React from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { ButtonLoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { isInitial } from "@/__main__/constants.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { useCurrentAccount } from "@/app/util.mjs";
import { InfoModal } from "@/game-destiny2/components/InfoModal.jsx";
import { LoadoutBuilderArmor } from "@/game-destiny2/components/LoadoutBuilderArmor.jsx";
import { LoadoutBuilderOverview } from "@/game-destiny2/components/LoadoutBuilderOverview.jsx";
import { LoadoutBuilderWeapon } from "@/game-destiny2/components/LoadoutBuilderWeapons.jsx";
import { OAuthPermissionBasic } from "@/game-destiny2/components/OAuthPermissionBasic.jsx";
import { CLASS } from "@/game-destiny2/constants/class.mjs";
import { RACE } from "@/game-destiny2/constants/race.mjs";
import { GAME_SYMBOL_DESTINY2 } from "@/game-destiny2/definition-symbol.mjs";
import type { CharacterLoadout } from "@/game-destiny2/models/bungie-character-loadout.mjs";
import {
  hideInfoModal,
  setSelectedCharacterId,
} from "@/game-destiny2/utils/actions.mjs";
import { useActiveInventoryUpdate } from "@/game-destiny2/utils/use-inventory-actions.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Styled = {
  loadoutCss: () => css`
    .content {
      border-top: 1px solid var(--shade3-15);
      padding-top: var(--sp-4);

      display: flex;
      flex-direction: column;
      gap: var(--sp-4);
    }

    button.selectButton {
      --border: transparent;
      --btn-height: var(--sp-12);
      --bg: transparent;

      padding-left: 0;

      .text {
        font-size: 1.5rem;
        line-height: 2.25rem;
        font-weight: 600;
      }

      .image {
        img {
          width: var(--sp-10);
          height: var(--sp-10);
        }
      }
    }
  `,
};

const BASE_URL = "/destiny2/loadout-builder";
const TABS = {
  overview: {
    isDefault: true,
    text: ["common:overview", "Overview"],
    url: `${BASE_URL}/overview`,
    component: LoadoutBuilderOverview,
  },
  armor: {
    text: ["common:armor", "Armor"],
    url: `${BASE_URL}/armor`,
    component: LoadoutBuilderArmor,
  },
  weapons: {
    text: ["common:weapons", "Weapons"],
    url: `${BASE_URL}/weapons`,
    component: LoadoutBuilderWeapon,
  },
} as const;

const DEFAULT_ID = "__default__";
function LoadoutBuilder() {
  const {
    parameters: [tab],
  } = useRoute();
  const { t } = useTranslation();
  const {
    destiny2: { selectedCharacterId, characterLoadout, oauth, showInfoModal },
    volatile: { d2ApiUnavailable },
  } = useSnapshot(readState);
  const currentProfile = useCurrentAccount(GAME_SYMBOL_DESTINY2);
  const tokenLoaded = !oauth[isInitial];
  const hasToken = !!oauth[currentProfile?.bungieId];

  const characters: Record<string, CharacterLoadout> =
    characterLoadout.characters ?? {};
  const charIds = Object.keys(characters);
  const charId = selectedCharacterId ?? charIds[0];

  const showInfo =
    showInfoModal && !showInfoModal[isInitial] && showInfoModal.show;

  const Component = (TABS[tab] ?? TABS.overview).component;
  return (
    <Container className={Styled.loadoutCss()}>
      {!d2ApiUnavailable && tokenLoaded && !hasToken && (
        <OAuthPermissionBasic />
      )}
      <PageHeader
        title={
          <Select
            selected={charId ?? DEFAULT_ID}
            disabled={!charId}
            onChange={setSelectedCharacterId}
            options={
              charId
                ? Object.entries(characters).map(([id, c]) => ({
                    text: [
                      "destiny2:guardianName",
                      "{{race}} {{class}}",
                      {
                        race: t(...RACE[c.raceHash].label),
                        class: t(...CLASS[c.classHash].label),
                      },
                    ],
                    value: id,
                    image: `${appURLs.BUNGIE}${c.emblemPath}`,
                  }))
                : [
                    {
                      text: ["destiny2:loadoutBuilder", "Loadout Builder"],
                      value: DEFAULT_ID,
                      image: `${appURLs.CDN_PLAIN}/blitz/ui/img/fallback.svg`,
                    },
                  ]
            }
          />
        }
        links={Object.values(TABS)}
      />
      <div className="content">
        <RefreshButton lastSync={characterLoadout.lastSync} />
        <Component selectedCharId={charId} />
      </div>
      {showInfo && tokenLoaded && (
        <InfoModal onClose={hideInfoModal} hasToken={hasToken} />
      )}
    </Container>
  );
}

function RefreshButton({ lastSync }: { lastSync: Date }) {
  const { t } = useTranslation();
  const isLoaded = useIsLoaded();
  const { searchParams, currentPath } = useRoute();
  const updateInProgress = useActiveInventoryUpdate();

  const isLoading = updateInProgress || !isLoaded || !lastSync;
  return (
    <div className="w-full flex justify-end">
      <Button
        disabled={isLoading}
        onClick={() => {
          updateRoute(currentPath, searchParams, { isSync: true });
        }}
        iconLeft={isLoading ? <ButtonLoadingSpinner /> : undefined}
        data-tip={
          !isLoading
            ? ReactDOMServer.renderToStaticMarkup(
                <div className="flex column shade1 type-caption">
                  <p className="type-overline">
                    {t(
                      "destiny2:syncCharacter.tip.title",
                      "Refresh Character Data",
                    )}
                  </p>
                  <span className="shade0">
                    {t("destiny2:syncCharacter.tip.sync", "Last sync:")}{" "}
                    <TimeAgo date={lastSync} />
                  </span>
                  <span>
                    {t(
                      "destiny2:syncCharacter.tip.copy",
                      "Bungie might repeat old data if not enough time has passed",
                    )}
                  </span>
                </div>,
              )
            : undefined
        }
      >
        {t("destiny2:syncCharacter.text", "Sync Character")}
      </Button>
    </div>
  );
}

export default LoadoutBuilder;

// TODO
export const meta = () => ({
  title: ["destiny2:builder.seo.title", "Loadout Builder"],
  description: [
    "destiny2:builder.seo.desc",
    "Streamline your Destiny 2 inventory with Blitz.gg’s advanced Destiny Item Manager. Easily organize gear, transfer items between guardians, and optimize your loadouts on the go, ensuring you're always ready for battle with the perfect setup.",
  ],
});
