export const RACE = {
  "2803282938": {
    slug: "awoken",
    referenceId: 2803282938,
    label: ["gen-destiny2:awoken.name", "Awoken"],
  },
  "3887404748": {
    slug: "human",
    referenceId: 3887404748,
    label: ["gen-destiny2:Human.name", "Human"],
  },
  "898834093": {
    slug: "exo",
    referenceId: 898834093,
    label: ["gen-destiny2:exo.name", "Exo"],
  },
} as const;

export const RACE_BY_ENUM = Object.fromEntries(
  Object.values(RACE).map((c) => [c.slug, c]),
);
